<template>
  <div class="component-wrapper">
    <b-card>
      <b-button :variant="selectedNav == `expiring-order` ? `danger` : `outline-danger`" @click.stop="selectNav(`expiring-order`)">
        <feather-icon icon="BellIcon"></feather-icon>
        Expiring Order
      </b-button>
      <b-button class="ml-1" :variant="selectedNav == `currency-rate` ? `danger` : `outline-danger`" @click.stop="selectNav(`currency-rate`)">
        <feather-icon icon="DollarSignIcon"></feather-icon>
        Currency Rate
      </b-button>
    </b-card>
    <component :is="showedComponent" />
  </div>
</template>

<script>
const CurrencyRate = () => import("./components/CurrencyRate.vue");
const ExpiringOrder = () => import("./components/ExpiringOrder.vue");
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  name: `MainDashboard`,
  components: { ExpiringOrder, CurrencyRate, FeatherIcon },
  data() {
    return {
      selectedNav: `expiring-order`,
    };
  },
  computed: {
    showedComponent() {
      let component;
      switch (this.selectedNav) {
        case `expiring-order`:
          component = ExpiringOrder;
          break;
        case `currency-rate`:
          component = `currency-rate`;
          break;
        default:
          component = `expiring-order`;
          break;
      }
      return component;
    },
  },
  methods: {
    selectNav(nav) {
      this.selectedNav = nav;
    },
  },
};
</script>
